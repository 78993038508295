<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-save-web-api-kullanici"
      title="Kullanıcı"
      no-close-on-backdrop
      centered
      @hidden="hiddenModal"
      v-if="modalObject.item"
    >
      <validation-observer ref="saveWebApiKullanici">
        <b-form ref="form" @submit.stop.prevent="saveDBKullanici">
          <b-form-group :label="$t('Ad')" label-for="sAdi">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Ad"
            >
              <b-form-input
                id="name"
                v-model="modalObject.item.sAdi"
                :placeholder="$t('Ad')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group :label="$t('Veri Tabanı')" label-for="dbFirmaInfo_Id">
            <db-firma-info-selection :dbinfoSelect.sync="dbFirmaInfo_Id" />
          </b-form-group>
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('Kullanıcı Adı')"
                label-for="sKullaniciAdi"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Kullanıcı Adı"
                >
                  <b-form-input
                    v-model="modalObject.item.sKullaniciAdi"
                    type="text"
                    :placeholder="$t('Kullanıcı Adı')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group :label="$t('Parola')" label-for="sParola">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Parola"
                >
                  <b-form-input
                    v-model="modalObject.item.sParola"
                    type="text"
                    :placeholder="$t('Parola')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" lg="6">
              <b-form-group :label="$t('Telefon')" label-for="sTelefon">
                <cleave
                  id="sTelefon"
                  v-model="modalObject.item.sTelefon"
                  placeholder="(999) 999-9999"
                  class="form-control"
                  :raw="false"
                  :options="options.phone"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="6">
              <b-form-group :label="$t('E-Posta')" label-for="sEposta">
                <validation-provider
                  #default="{ errors }"
                  rules="email"
                  name="E-Posta"
                >
                  <b-input-group>
                    <b-form-input
                      id="sEposta"
                      :placeholder="$t('E-Posta')"
                      v-model="modalObject.item.sEposta"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('Görev Tanımı')"
                label-for="sGorevTanimi"
              >
                <b-input-group>
                  <b-form-input
                    id="sGorevTanimi"
                    :placeholder="$t('Görev Tanımı')"
                    v-model="modalObject.item.sGorevTanimi"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button
          
          variant="outline-danger"
          class="float-left"
          @click="engelKaldir"
        >
          {{ $t("Engeli Kaldır") }}
        </b-button>
        <b-button variant="primary" class="float-right" @click="handleOk">
          {{ $t("Kaydet") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'
import { required } from '@validations';
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import DbFirmaInfoSelection from '../../selection/DbFirmaInfoSelection.vue';

export default {
  components: {
    DbFirmaInfoSelection,
    ValidationObserver,
    ValidationProvider,
    Cleave,
  },
  props: {
    modalObject: {
      type: Object,
       default() {
        return {}
      },
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      dbFirmaInfo_Id: 0,
      required,
      options: {
        phone: {
          blocks: [0, 3, 0, 3, 4],
          delimiter: ' ',
          delimiters: ['(', ')', ' ', '-', '-'],
          numericOnly: true,
          uppercase: false,
          lowercase: false,
        },
      },
    }
  },
  methods: {
    saveWebApiKullanici() {
       this.$refs.saveWebApiKullanici.validate().then(success => {
        if (success) {
          if (this.dbFirmaInfo_Id == null) {
            AlertService.warning(this, 'Veri tabanı seçiniz');
            return;
          }
          this.modalObject.item.dbFirmaInfo_Id = this.dbFirmaInfo_Id;
          this.$store.dispatch('dbKullaniciCustomer/saveDBKullanici', this.modalObject.item, this)
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              })
              this.modalObject.onSuccess();
            } else {
              AlertService.error(this, response.message)
            }
          }); 
        }
      });
    },
    engelKaldir() {
        AlertService.confirmSuccess(this, () => {
          this.$store.dispatch('dbKullaniciCustomer/engelKaldir', this.modalObject.item.sKullaniciAdi)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                });
              } else {
                AlertService.error(this, response.message);
              }
            })
        })
    },
    initialModal() {
      this.dbFirmaInfo_Id = this.modalObject.item.dbFirmaInfo_Id;
      this.$refs['modal-save-web-api-kullanici'].show();
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.saveWebApiKullanici()
    },
    hiddenModal() {
      this.$refs['modal-save-web-api-kullanici'].hide()
      this.modalObject.showModal = false;
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3"
          class="mb-md-0 mb-2">
          <label> {{ $t('Veri Tabanı') }}</label>
          <db-firma-info-selection :dbinfoSelect.sync="dbFirmaIdFilter" />
        </b-col>
        <b-col cols="12" md="3" 
          class="mb-md-0 mb-2">
          <label>{{ $t('Aktif Durumu') }} </label>
          <b-form-group>
            <v-select
              v-model="isActiveFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="webApiKullaniciDurumOptions"
              class="w-100"
              :reduce="val => val.value"
              :placeholder="$t('Aktif Durumu Seç')" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3"
          class="mb-md-0 mb-2">
          <label>Arama</label>
          <b-form-input
            v-model="sKullaniciAdiFilter"
            class="d-inline-block mr-1"
            :placeholder="$t('Ara...')" />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>

import StaticOptions from '@/common/options/StaticOptions'
import DbFirmaInfoSelection from '../../selection/DbFirmaInfoSelection.vue'

export default {
  components: {
    DbFirmaInfoSelection,
    
  },
  data() {
    return {
     webApiKullaniciDurumOptions: StaticOptions.webApiKullaniciDurumOptions,
    }
  },
  computed: {
    isActiveFilter: {
      get() {
        return this.$store.getters['dbKullaniciCustomer/getWebApiKullaniciLocalFilters'].isActive
      },
      set(value) {
        this.$store.commit('dbKullaniciCustomer/setWebApiKullaniciFilter', { key: 'isActive', value })
      },
    },
    dbFirmaIdFilter: {
      get() {
        return this.$store.getters['dbKullaniciCustomer/getWebApiKullaniciLocalFilters'].dbFirmaInfo_Id
      },
      set(value) {
        this.$store.commit('dbKullaniciCustomer/setWebApiKullaniciFilter', { key: 'dbFirmaInfo_Id', value })
      },
    },
    sKullaniciAdiFilter: {
      get() {
        return this.$store.getters['dbKullaniciCustomer/getWebApiKullaniciLocalFilters'].sKullaniciAdi
      },
      set(value) {
        this.$store.commit('dbKullaniciCustomer/setWebApiKullaniciFilter', { key: 'sKullaniciAdi', value })
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
